<template>
	<div>
		<div class="bg-F7F7F7 w100 paddt20" v-if="titleIndex == 1">
			<div class="w1200 main bg-white display-flex">
				<div class="flex-1"  @contextmenu.prevent="menuPlayer()">
					<video-player class="video-player   vjs-custom-skin" ref="videoPlayers" controlslist="nodownload" :playsinline="true"
						:options="playerOptions"  />
				</div>
			</div>
			<div class="mart10 center flex-column">
				<div class="h54 w1200 bg-white boderb1-E6E6E6">
					<div class="paddlr20 fz16 display-flex h54">
						<div class="center h54 cure-pointer" @click="titleChoose(i)"
							:class="[i == titleIndex ? 'co-010085' : 'co-333333', i == 0 ? 'marr44' : '']"
							v-for="(item, i) in titleList" :key="i">{{item}}</div>
					</div>
				</div>
				<div class="w1200 bg-white">
					<div class="padd21">
						<div class="catalogue">
							<div class="" v-for="(item, i) in classDetail.chapters" :key="i">
								<div class="paddlr20 h44 align-items-center fz16 co-333333 bg-F7F7F7">{{item.title}}
								</div>
								<div class="paddlr20 h44 align-items-center fz16 cure-pointer  "
									:class="{'acitve':videoId == e.id}" v-for="(e,index) in item.children"
									:key="index">
									<img v-if="videoId == e.id" class="cd_01 display-block "
										src="@/assets/img/cd_02.png" alt="" />
									<img v-else class="cd_01 display-block" src="@/assets/img/cd_01.png" alt="" />
									<span class="fz16 co-999999 display-block marl14 "
										:class="{'spans':videoId == e.id}"
										@click="chooseVedio(e)">{{e.title}}</span>
								</div>
							</div>
						
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="bg-F7F7F7" v-if="titleIndex == 0">
			<div class="relative">
				<img class="bg-pic w100 display-block" src="@/assets/img/login_bg.png" alt="" />
				<div class="absolute top0 left0 right0 bottom0">
					<div class="w1200 top-main paddt85 display-flex">
						<el-image class="img_02 display-block"  :src="classDetail.image" lazy fit="cover"></el-image>
						<div class="flex-1 marl22">
							<div class="fz28 co-FFFFFF font-blod">{{classDetail.title}}</div>
							<div class="fz24 co-FFFFFF mart14"></div>
							<div class="align-items-center mart38">
								<img class="display-block de_01" src="@/assets/img/de_01.png" alt="" />
								<span class="fz24 co-FFFFFF marl12">{{classDetail.create_time}}发布</span>
								<img class="display-block marl46 de_01" src="@/assets/img/de_see.png" alt="" />
								<span class="fz24 co-FFFFFF marl12">{{classDetail.view}}</span>
								<img class="display-block marl46 de_01" src="@/assets/img/de_03.png" alt="" />
								<span class="fz24 co-FFFFFF marl12">{{classDetail.favorite}}</span>
							<!-- 	<img class="display-block marl46 de_01" src="@/assets/img/down.png" alt="" />
								<span class="fz24 co-FFFFFF marl12">{{classDetail.download}}</span> -->
							</div>
							<div class="mart31 justify-content-center-between">
								<div class="align-items-center cure-pointer " @click="rightClick" > 
									<img class="display-block de_01 cure-pointer" src="@/assets/img/de_04.png" alt="" />
									<img class="display-block de_01 marl32 cure-pointer" src="@/assets/img/de_02.png"
										alt="" />
									<img class="display-block de_01 marl32 cure-pointer" src="@/assets/img/de_05.png"
										alt="" />
									<img class="display-block de_01 marl32 cure-pointer" src="@/assets/img/de_06.png"
										alt="" />
								</div>

								<div class="align-items-center">
									<div class="btn-01 cure-pointer center" @click="like(2)">
										<img class="display-block de_01"
											:src="require(`@/assets/img/${classDetail.is_collect==!1 ? 'de_collect' : 'me_collect'}.png`)"
											alt="" />
										<span class="fz22 display-block marl14"
											:class="classDetail.is_collect==!1 ? 'co-010085' : 'co-333333'">{{classDetail.collect==1 ? '已收藏' : '收藏'}}</span>
									</div>

									<div class="btn-01 cure-pointer center marl30" @click="like(1)">
										<img class="display-block de_01"	
											:src="require(`@/assets/img/${classDetail.is_favorite ?  'me_like' : 'de_like'}.png`)"
											alt="" />
										<span class="fz22 display-block marl14"
											:class="classDetail.is_favorite ? ' co-333333' : 'co-010085'">{{classDetail.favorite}}</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>

			<div class="price h120 w1200 relative">
				<div
					class="absolute left0 top0 right0 bottom0 justify-content-center-between boderra4 bg-white price-main paddlr30">
					<div class="align-items-end">
						<span class="fz56 l46 co-010085">￥{{classDetail.price}}</span>
						<span class="fz24 co-333333 display-block marl8">元</span>
					</div>
					<div class="down-btn center  co-010085 font-blod cure-pointer" @click="imBuy">
						<span class="fz22 font-blod">购买：￥{{classDetail.price}}</span>
						<span class="fz16">{{btnStyle.buttonText}}</span>
						<span class="fz14">{{btnStyle.buttonText2}}</span>
					</div>
				</div>
			</div>

			<div class="paddt20 center bg-F7F7F7">
				<div class="h54 w1200 bg-white boderb1-E6E6E6">
					<div class="paddlr20 fz16 display-flex h54">
						<div class="center h54 cure-pointer" @click="titleChoose(i)"
							:class="[i == titleIndex ? 'co-010085' : 'co-333333', i == 0 ? 'marr44' : '']"
							v-for="(item, i) in titleList" :key="i">{{item}}</div>
					</div>
				</div>
			</div>

			<div class="bg-F7F7F7">
				<div class="w1200 main bg-white">
					<div v-html="classDetail.content" class="html-box  ">
					</div>
				</div>
			</div>
		</div>
		 <div v-loading.fullscreen.lock="fullscreenLoading"></div>
		 <tgshare ref="sharePopup" :dataObj="dataObj"  :shareUrl="'https://jianzhupc.togy.com.cn/#/ClassDetail?id='+courseid" ></tgshare>
	</div>
</template>

<script>
	import {
		useRoute
	} from 'vue-router';
	export default {
		data() {
			return {
				dataObj: {},
				obj: {
					type: ''
				}, //评论的参数
				titleList: ['课程介绍', '课程目录'], // 子导航
				titleIndex: 0, // 子导航选中
				courseid: '',
				classDetail: {}, //课程信息
				videoId: '', //视频id
				fullscreenLoading:true,//加载中
				btnStyle:{
					buttonText: '',
					buttonText2:''
				},
				videoUrl:'',
				playerOptions: {
					playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
					autoplay: false, //如果true,浏览器准备好时开始回放。
					muted: false, // 默认情况下将会消除任何音频。
					loop: false, // 导致视频一结束就重新开始。
					preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
					language: 'zh-CN',
					aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
					fluid: false, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
					sources: [{
						type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
						src: "" // url地址
					}],
					
					poster: "", // 你的封面地址				
					notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
					controlBar: {
					          //当前时间和持续时间的分隔符
					          timeDivider: true,
					          //显示持续时间
					          durationDisplay: true,
					          //是否显示剩余时间功能
					          remainingTimeDisplay: false,
					          //全屏按钮
					          fullscreenToggle: true,
					          //播放暂停按钮
					          playToggle: true,
					          //音量控制
					          volumeMenuButton: false,
					          //当前播放时间
					          currentTimeDisplay: true,
					          //点播流时，播放进度条，seek控制
					          progressControl: true,
					          //直播流时，显示LIVE
					          liveDisplay: true,
					          //播放速率，当前只有html5模式下才支持设置播放速率
					          playbackRateMenuButton: true
					        }
				},
			}
		},
		created() {
			let data = useRoute().query;
			this.courseid = data.id;
			this.getDetail()
			 $("video").bind("contextmenu",function(){//取消右键事件
			        return false;
			    });
		},
		methods: {
			rightClick() {
				this.$refs.sharePopup.open()
			},
			getDetail() {
				this.$req({
					method: 'get',
					url: '/api/course/detail',
					data: {
						id: this.courseid
					},
					success: res => {
						this.dataObj = { //分享参数
							title: res.title,
							id: this.courseid,
							type:'agency',
						}
						console.log(this.dataObj,'分享信息')
						this.fullscreenLoading =false;
						
						this.classDetail = res;
						// this.classDetail.can_watch 
						console.log(this.classDetail.can_watch,'++')
						if(this.classDetail.can_watch===0){
							this.playerOptions.notSupportedMessage = '请购买课程后，方可播放';
						}
						this.videoUrl = this.classDetail.chapters[0].children[0].video;
						this.videoId = this.classDetail.chapters[0].children[0].id;
						this.playerOptions.sources[0].src= this.videoUrl;
						
						if(res.cate_id == 1){
							this.btnStyle.buttonText = '去观看'
							this.btnStyle.buttonText2 = ''
						}else {
							console.log(res.is_vip_free,'课程信息')
							if(res.is_vip_free == 1){
								// 会员免费的
								this.btnStyle.buttonText = '购买：￥' + res.data.price
								this.btnStyle.buttonText2 = '会员免费'
							}else if(res.is_vip_free==0){
								this.btnStyle.buttonText2 = '';
								console.log('湿哒哒')
							}else {
								// 非会员免费的
								if (res.is_buy == 0 || res.data.is_buy == 2) {
									this.btnStyle.buttonText = '购买：￥' + res.data.price
									this.btnStyle.buttonText2 = ''
								} else {
									this.btnStyle.buttonText = '去观看'
									this.btnStyle.buttonText2 = ''
								}
							}
						}
					},
					fail: error => {
						this.fullscreenLoading =false;
					}
				});
			},
			// 子导航选中
			titleChoose(e) {
				this.titleIndex = e;
				// this.classDetail.chapters
				// if(this.titleIndex==1){
				// 	console.log(this.classDetail.chapters)
				// }
				
			},
			// 点赞
			like(e) {
			
				let msg = '',
					key = '',
					type = ''
				if (e == 1) {		
					// 研图社
					type = 'course_favorite'
					
					msg = '点赞'
				} else {			
					type = 'course_collect'	
					msg = '收藏'
			
				}
				console.log({
					id: this.id,
					type,
				}, msg)
			
				this.$req({
					method: 'post',
					url: '/api/mapleCollection',
					data: {
						id: this.courseid,
						type: type,
					},
					success: res => {
						if (e == 2) {
							if (this.classDetail.is_collect == 1) {
								this.classDetail.is_collect = 0
								this.classDetail.collect--;
								this.$message.warning("取消收藏");
			
							} else {
								this.classDetail.is_collect = 1
								this.classDetail.collect++;
								this.$message.success("收藏成功");
							}
						}
						if (e == 1) {
							if (this.classDetail.is_favorite == 1) {
								this.classDetail.is_favorite = 0
								this.classDetail.favorite--;
								this.$message.warning("取消点赞");
							} else {
								this.classDetail.is_favorite = 1
								this.classDetail.favorite++;
								this.$message.success("点赞成功");
							}
						}
					},
					fail: error => {
						console.log(error)
					}
				});
			
			},
			chooseVedio(e) {
				// 选择视频
			
				this.$refs.videoPlayers.player.pause();
				this.playerOptions.sources[0].src = e.video;
				console.log(e.video)
				this.videoId = e.id;				
				setTimeout(() => {
					this.$refs.videoPlayers.player.play()
				}, 500)
			},
			// 立即购买
			imBuy() {
				if (this.classDetail.cate_id == 1) {
					// 公开课点击到目录
					this.titleIndex = 1
					this.videoUrl = this.classDetail.video
					return
				}
				if(this.classDetail.can_watch){
					this.titleIndex = 1
					this.videoUrl = this.classDetail.video
				}else {
					if ((this.classDetail.is_buy >= 0 && this.classDetail.is_buy != 1) && this.classDetail.cate_id != 1) {
						// 判断是否存在支付订单
						if (this.classDetail.is_buy == 2) {
								this.$message.warning('已存在未支付订单,去个人中心查看相关订单')
						} else if (this.classDetail.is_buy == 1) {
						
								this.$message.warning('您已购买，请勿重复购买')
						} else {
							// getApp().globalData.orderDetail = this.classDetail
							// getApp().globalData.orderDetail.type = 'course'
							// uni.navigateTo({
							// 	url: '/pages/indexPart/sureOrder/sureOrder?type=2'
							// })
							console.log('需要购买')
							let  orderDetails 
							orderDetails =  this.classDetail;
							orderDetails.type = 'course';
							this.$router.push({
								path: '/SubmitOrder',
								query: {
									type: 2,
									orderDetail:JSON.stringify(orderDetails)
								}
							})
						}
					} else {
						// 下载目录
						this.titleIndex = 1
						this.videoUrl = this.classDetail.video
					}
				}
				 
			},
		}
	}
</script>


<style >
	.video-js .vjs-slider {
	    background-color: #ffffff;
	  }
	.vjs-custom-skin > .video-js .vjs-play-progress, .vjs-custom-skin > .video-js .vjs-volume-level {
	   background-color: #DCFF03;
	  }
	
	.acitve {
		background: #F9FFD6;
	
	}
	
	.spans {
		color: #010085 !important;
	}
	
.down-btn {
		width: 188px;
		height: 80px;
		background: #DCFF03;
		border-radius: 4px;
		flex-direction: column;
	}
	

	.app_05 {
		width: 1200px;
		height: 675px;
	}

	.catalogue {
		width: 1160px;
		border: 1px solid #E6E6E6;


	}

	.cd_01 {
		width: 22px;
		height: 22px;
	}

	.bg-pic {
		height: 450px;
		filter: blur(16px);
		-webkit-filter: blur(15px);
	}

	.top-main {
		margin: 0 auto;


	}

	.img_02 {
		width: 285px;
		height: 220px;
		border-radius: 8px;
	}

	.de_01 {
		width: 32px;
		height: 32px;
	}

	.btn-01 {
		padding: 0 20px;
		height: 50px;
		background: #FFFFFF;
		opacity: 1;
		border-radius: 30px;
	}

	.price {
		margin: 0 auto;
		margin-top: -60px;


	}

	.main {
		margin: 0 auto;


	}

	.main-pic {
		height: 450px;
	}

	.main-content {
		padding-left: 20px;
		padding-right: 19px;
	}

	.textarea {
		padding: 10px;
		width: 1140px;
		height: 54px;
		outline: none;
		background: #F7F7F7;
		border: 0;
		resize: none;
	}

	.head-pic-01 {
		width: 24px;
		height: 24px;
		border-radius: 50%;
	}

	.de_08 {
		width: 20px;
		height: 20px;
	}

	.submit-btn {
		width: 72px;
		height: 28px;
		background: #DCFF03;
		border-radius: 3px;
	}

	.head-pic-02 {
		width: 30px;
		height: 30px;
		border-radius: 50%;
	}

	.comment-like {
		width: 20px;
		height: 20px;
	}
</style>
